<template>
  <div>
    <el-dialog
      title="Attachment"
      :visible="dialogAttachment"
      top="12vh"
      width="800px"
      :close-on-click-modal="false"
      @close="handleAttachmentDialogClose">
      <div class="dialog-container">
        <div v-if="fileList.length > 0" class="attachments-container">
          <div
            class="attachment-item"
            v-for="item in fileList"
            :key="item.id">
            <span class="attachment-name">{{item.originalName || item.name}}</span>
            <div class="button-group">
              <el-button
                round
                plain
                size="small"
                type="primary"
                @click="previewFile(item)"
              >View</el-button>
              <el-popconfirm
                title="Are you sure you want to delete the file"
                style="margin-left: 8px;"
                @confirm="handleFileRemoval(item)"
              >
                <el-button
                  slot="reference"
                  round
                  plain
                  size="small"
                  type="danger"
                >Delete</el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <img src="@/assets/images/no_data.png" alt="">
          <span>No Files</span>
        </div>
      </div>
      <span class="dialog-footer">
        <el-upload
          multiple
          accept=".pdf,.jpg,.doc,.docx,.jpeg"
          :show-file-list="false"
          :action="uploadUrl"
          :file-list="fileList"
          :on-success="handleSuccessFileUpload">
          <el-button
            type="primary"

            round>
            Upload Order Files
          </el-button>
          <div class="el-upload__tip">
            Tip: Only Pdf/Jpeg/Word files can be uploaded
          </div>
        </el-upload>
      </span>
    </el-dialog>
    <file-preview :visible.sync="isFilePreview" :file="currentFile"></file-preview>
  </div>
</template>

<script>
import FilePreview from '@/components/FilePreview'

export default {
    name: 'DialogAttachment',
    components: {
      FilePreview
    },
    props: {
      dialogAttachment: {
        type: Boolean,
        default: () => false
      },
      order: {
        type: Object
      }
    },
    data () {
      return {
        fileList: [],
        currentFile: null,
        isFilePreview: false
      }
    },
    computed: {
      uploadUrl () {
        return this.$apis.baseUrl + this.$apis.uploadFile
      }
    },
    watch: {
      dialogAttachment (newValue, oldValue) {
        if(!oldValue && newValue) {
          this.processFiles(this.order.salesOrderFiles, this.order.purchaseOrderFiles)
        }
      }
    },
    methods: {
      handleFileRemoval (file) {
        return new Promise((resolve, reject) => {
          this.$request.post({
            url: this.$apis.deleteFile,
            data: [file.id]
          }).then(res => {
            if (res?.code === 1000) {
              this.fileList = this.fileList.filter(item => item.id !== file.id)
              return resolve()
            }
            return reject(new Error())
          }).catch(e => {
            return reject(new Error())
          })
        })
      },
      handleSuccessFileUpload (response, file) {
        const { id } = response.data
        file.id = id
        this.linkAttachment(file)
      },
      handleFileChange (file, fileList) {
        this.fileList = fileList
      },
      previewFile (file) {
        this.currentFile = file
        this.isFilePreview = true
      },
      handleAttachmentDialogClose () {
        this.fileList = []
        this.$emit('handleAttachmentDialogClose')
      },
      removeFile (file) {
        this.fileList = this.fileList.filter(item => item.id !== file.id)
      },
      linkAttachment (file) {
        this.$request.post({
          url: `${this.$apis.orders}/${this.order.id}/link_attachments`,
          data: [file.id]
        }).then(res => {
          if(res?.code === 1000) {
            this.$message.success(`'${file.name}' uploaded successfully!`)
            this.processFiles(res.data.salesOrderFiles, res.data.purchaseOrderFiles)
          }
          else {
            this.removeFile(file)
          }
        }).catch(e => {
          this.removeFile(file)
        })
      },
      processFiles (salesOrderFiles, purchaseOrderFiles) {
        const typedSalesOrderFiles = salesOrderFiles.map(s => ({ type: 'sales', ...s  }))
        const typedPurchaseOrderFiles = purchaseOrderFiles.map(p => ({ type: 'purchase', ...p }))
        this.fileList = [...typedSalesOrderFiles, ...typedPurchaseOrderFiles]
      }
    }
}
</script>

<style lang='scss' scoped>
/deep/ .el-dialog {
  max-width: 1976px;

  .el-dialog__title {
    font-weight: 700;
    font-size: 20px;
  }
}
.dialog-container {
  // @include flex-center-column;
  min-height: 45vh;
  max-height: 63vh;
  overflow-y: auto;
}
.attachments-container {
  width: 100%;
  .attachment-item {
    border-bottom: 1px solid #eee;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attachment-name {
      font-size: 15px;
    }
  }
}
.no-data {
  @include flex-center-column;
  margin: auto;
  padding-bottom: 10vh;

  img {
    width: 200px;
    height: 200px;
  }

  span {
    font-size: 16px;
    color: #C0C4CC;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  padding: 5px;
  /deep/ .el-button {
    padding: 12px 45px;
  }
  .el-upload__tip {
    color: #909399;
  }
}
</style>
