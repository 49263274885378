var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Attachment",
            visible: _vm.dialogAttachment,
            top: "12vh",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: { close: _vm.handleAttachmentDialogClose },
        },
        [
          _c("div", { staticClass: "dialog-container" }, [
            _vm.fileList.length > 0
              ? _c(
                  "div",
                  { staticClass: "attachments-container" },
                  _vm._l(_vm.fileList, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "attachment-item" },
                      [
                        _c("span", { staticClass: "attachment-name" }, [
                          _vm._v(_vm._s(item.originalName || item.name)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "button-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  round: "",
                                  plain: "",
                                  size: "small",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewFile(item)
                                  },
                                },
                              },
                              [_vm._v("View")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: {
                                  title:
                                    "Are you sure you want to delete the file",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handleFileRemoval(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      round: "",
                                      plain: "",
                                      size: "small",
                                      type: "danger",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("Delete")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "no-data" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/no_data.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v("No Files")]),
                ]),
          ]),
          _c(
            "span",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    multiple: "",
                    accept: ".pdf,.jpg,.doc,.docx,.jpeg",
                    "show-file-list": false,
                    action: _vm.uploadUrl,
                    "file-list": _vm.fileList,
                    "on-success": _vm.handleSuccessFileUpload,
                  },
                },
                [
                  _c("el-button", { attrs: { type: "primary", round: "" } }, [
                    _vm._v(" Upload Order Files "),
                  ]),
                  _c("div", { staticClass: "el-upload__tip" }, [
                    _vm._v(" Tip: Only Pdf/Jpeg/Word files can be uploaded "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("file-preview", {
        attrs: { visible: _vm.isFilePreview, file: _vm.currentFile },
        on: {
          "update:visible": function ($event) {
            _vm.isFilePreview = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }